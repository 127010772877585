<template>
  <v-container fluid>
    <h2 class="mb-4 blue--text">
      {{ $attrs.selectedClassGroup.STUDENT_GROUP_NAME }}
    </h2>
    <v-data-table
      :headers="headers"
      :items="sortedItems"
      item-value="category"
      group-by="categoryID"
      class="elevation-1 fixed-table"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:group.header="{ items }">
        <td
          :colspan="headers.length"
          class="group-header"
          style="background: #f8f8f8 !important"
        >
          <strong>{{ items[0].category }}</strong>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn class="bg-gradient-danger" dark small @click="_doUnelgee(item)"
          >Үнэлгээ+</v-btn
        >
      </template>

      <template v-slot:item.icons>
        <v-icon small class="mr-6" @click="_addStudentToLesson"
          >mdi-account-plus</v-icon
        >
        <v-icon small @click="_removeStudentToLesson">mdi-account-minus</v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="addStudentToLessonDialog"
      max-width="1000px"
      v-if="$attrs.selectedClassGroup"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Шалгуулагч бүртгэх
            <!-- <span
              class="blue--text"
              v-if="selectedLesson && selectedLesson.classGroups"
              >{{
                selectedLesson.classGroups
                  .map((a) => a.classGroupFullName)
                  .join(", ")
              }}</span
            > -->
          </span>
        </div>
        <v-card-text class="card-padding">
          <p
            class="text-end"
            v-if="selectedAddStudents && selectedClassGroupStudents"
          >
            <span class="green--text"
              >Сонгогдсон: {{ selectedAddStudents.length }}</span
            >
            |
            <span class="red--text">
              Сонгогдоогүй:
              {{
                selectedClassGroupStudents.length - selectedAddStudents.length
              }}</span
            >
          </p>
          <v-data-table
            v-if="
              selectedClassGroupStudents &&
              selectedClassGroupStudents.length > 0
            "
            height="40vh"
            v-model="selectedAddStudents"
            :headers="headers2"
            :items="selectedClassGroupStudents"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
          </v-data-table>
          <v-progress-linear
            v-else
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              addStudentToLessonDialog = false;
              loadingStudents = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Буцаx
          </v-btn>

          <v-btn
            @click="_saveSelectedStudents"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Бүртгэx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removeStudentToLessonDialog"
      max-width="1000px"
      v-if="$attrs.selectedClassGroup"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Шалгуулагч хасах
            <!-- <span
              class="blue--text"
              v-if="selectedLesson && selectedLesson.classGroups"
              >{{
                selectedLesson.classGroups
                  .map((a) => a.classGroupFullName)
                  .join(", ")
              }}</span
            > -->
          </span>
        </div>
        <v-card-text class="card-padding">
          <p
            class="text-end"
            v-if="selectedRemoveStudents && selectedClassGroupStudents"
          >
            <span class="green--text"
              >Сонгогдсон: {{ selectedRemoveStudents.length }}</span
            >
            |
            <span class="red--text">
              Сонгогдоогүй:
              {{
                selectedClassGroupStudents.length -
                selectedRemoveStudents.length
              }}</span
            >
          </p>
          <v-data-table
            v-if="
              selectedClassGroupStudents &&
              selectedClassGroupStudents.length > 0
            "
            height="40vh"
            v-model="selectedRemoveStudents"
            :headers="headers2"
            :items="selectedClassGroupStudents"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
          </v-data-table>
          <v-progress-linear
            v-else
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedRemoveStudents = null;
              removeStudentToLessonDialog = false;
              loadingStudents = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Буцаx
          </v-btn>

          <v-btn
            @click="_saveRemoveSelectedStudents"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xасаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addUnelgeeDialog" max-width="60%">
      <v-card>
        <v-card-title>
          <span class="font-weight-bold">
            Үнэлгээний бүртгэл | 12-р ангийн Математик | Заавал</span
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="addUnelgeeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="unelgeeDialogHeaders"
            :items="selectedAddStudents"
            dense
            class="elevation-1"
            hide-default-footer
            :items-per-page="-1"
            fixed-header
            height="600px"
          >
            <template v-slot:item.finalScore="{ item }">
              <v-text-field
                v-model="item.finalScore"
                dense
                outlined
                hide-details
              ></v-text-field>
            </template>

            <template v-slot:item.status>
              <v-btn text color="primary">Батлагдаагүй</v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="addUnelgeeDialog = false"
            >Буцах</v-btn
          >
          <v-btn
            color="bg-gradient-info"
            dark
            v-if="selectedAddStudents && selectedAddStudents.length > 0"
            >Хадгалах</v-btn
          >
          <v-btn
            color="red"
            dark
            v-if="selectedAddStudents && selectedAddStudents.length > 0"
            >Илгээх</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      addUnelgeeDialog: false,

      unelgeeDialogHeaders: [
        { text: "№", value: "id", sortable: false },
        { text: "Овог нэр", value: "name" },
        { text: "Регистр", value: "register" },
        { text: "Ирц", value: "attendance" },
        { text: "Авсан оноо", value: "score", sortable: false },
        { text: "Хувь", value: "percentage" },
        { text: "Дүн", value: "grade" },
        { text: "Төлөв", value: "status", sortable: false },
      ],

      headers2: [
        {
          text: "№",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "FIRST_NAME",
        },
        {
          text: "Овог",
          align: "start",
          sortable: true,
          value: "LAST_NAME",
        },
        {
          text: "Бүлэг",
          align: "start",
          sortable: true,
          value: "STUDENT_GROUP_NAME",
        },

        {
          text: "Регистр",
          align: "start",
          sortable: true,
          value: "REGISTER",
        },
        {
          text: "Нэмсэн огноо",
          align: "start",
          sortable: true,
          value: "bday",
        },
      ],

      selectedRemoveStudents: null,
      removeStudentToLessonDialog: false,

      selectedAddStudents: null,
      addStudentToLessonDialog: false,

      selectedClassGroupStudents: null,

      loadingStudents: false,

      headers: [
        { text: "№", value: "id", fixed: true },
        { text: "Шалгалтын төрөл", value: "name", width: "230px" },
        { text: "Шалгуулагч", value: "icons", sortable: false },
        { text: "Үйлдэл", value: "actions", sortable: false },
        { text: "Шалгуулагч", value: "students", width: "5%" },
        { text: "Бүртгээгүй", value: "not_registered", width: "5%" },
        { text: "Бүртгэсэн", value: "registered", width: "5%" },
        { text: "Хянагдаж байгаа", value: "reviewing", width: "5%" },
        { text: "Хянагдсан", value: "reviewed", width: "5%" },
        { text: "Цуцлагдсан", value: "cancelled", width: "5%" },
        { text: "Баталсан", value: "approved", width: "5%" },
        { text: "Эхлэх хугацаа", value: "start_date" },
        { text: "Дуусах хугацаа", value: "end_date" },
      ],
      items: [
        {
          id: 1,
          category: "Заавал",
          categoryID: "1",
          name: "12-р ангийн Математик",
          students: 16,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 2,
          category: "Заавал",
          categoryID: "1",
          name: "12-р ангийн Нэгдмэл агуулга",
          students: 16,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 3,
          category: "Гадаад хэл",
          categoryID: "2",
          name: "12-р ангийн Англи хэл",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 4,
          category: "Гадаад хэл",
          categoryID: "2",
          name: "12-р ангийн Орос хэл",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 5,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Биологи",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 6,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Газарзүй",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 7,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Зурагзүй",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 8,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Нийгмийн ухаан",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 9,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Түүх",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 10,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Физик",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
        {
          id: 11,
          category: "Сонгон",
          categoryID: "3",
          name: "12-р ангийн Хими",
          students: 0,
          not_registered: 0,
          registered: 0,
          reviewing: 0,
          reviewed: 0,
          cancelled: 0,
          approved: 0,
          start_date: "2025-03-18",
          end_date: "2025-07-31",
        },
      ],
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    sortedItems() {
      return [...this.items].sort((a, b) => a.categoryID - b.categoryID);
    },
  },

  methods: {
    _doUnelgee(item) {
      console.log(item);
      this.addUnelgeeDialog = true;
    },
    _removeStudentToLesson() {
      this.loadingStudents = true;
      this.selectedRemoveStudents = [];
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where(
          "STUDENT_GROUP_ID",
          "==",
          this.$attrs.selectedClassGroup.STUDENT_GROUP_ID
        )
        .where("deleted", "==", false)
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          var counter = 0;
          this.selectedClassGroupStudents = [];
          docs.forEach(async (doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;

            if (!student.moved) {
              student.name = student.LAST_NAME
                ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                : "" + student.FIRST_NAME;
              student.bday = student.DATE_OF_BIRTH.replace(
                "T00:00:00.000Z",
                ""
              );

              counter++;
              student.index = counter;
              this.selectedClassGroupStudents.push(student);
            }
          });
          this.loadingStudents = false;
          this.removeStudentToLessonDialog = true;
        });
    },
    _addStudentToLesson() {
      this.loadingStudents = true;
      this.selectedAddStudents = [];

      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where(
          "STUDENT_GROUP_ID",
          "==",
          this.$attrs.selectedClassGroup.STUDENT_GROUP_ID
        )
        .where("deleted", "==", false)
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          var counter = 0;
          this.selectedClassGroupStudents = [];
          docs.forEach(async (doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;

            if (!student.moved) {
              student.name = student.LAST_NAME
                ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                : "" + student.FIRST_NAME;
              student.bday = student.DATE_OF_BIRTH.replace(
                "T00:00:00.000Z",
                ""
              );

              counter++;
              student.index = counter;
              this.selectedClassGroupStudents.push(student);
            }
          });
          this.loadingStudents = false;
          this.addStudentToLessonDialog = true;
        });
    },

    _saveSelectedStudents() {
      console.log("saveing....", this.selectedAddStudents);
      if (this.selectedAddStudents && this.selectedAddStudents.length > 0) {
        // var batch = fb.db.batch();
        // for (const student of this.selectedAddStudents) {
        //   var tmp = {};
        //   tmp["FIRST_NAME"] = student.FIRST_NAME;
        //   tmp["LAST_NAME"] = student.LAST_NAME;
        //   tmp["PERSON_ID"] = student.PERSON_ID;
        //   tmp["DATE_OF_BIRTH"] = student.DATE_OF_BIRTH;
        //   tmp["EMAIL"] = student.EMAIL ? student.EMAIL : null;
        //   tmp["STUDENT_GROUP_ID"] = student.STUDENT_GROUP_ID;
        //   tmp["STUDENT_GROUP_NAME"] = student.STUDENT_GROUP_NAME;
        //   tmp["GENDER_CODE"] = student.GENDER_CODE;
        //   tmp["classGroupRef"] =
        //     student["classGroup-" + this.userData.school.currentYear];
        //   tmp.selectedSemester = this.selectedSemester;
        //   tmp.addAtDate = new Date();
        //   tmp.addByRef = this.userData.ref;
        //   tmp.addByName = this.userData.DISPLAY_NAME
        //     ? this.userData.DISPLAY_NAME
        //     : this.userData.firstName
        //     ? this.userData.firstName
        //     : this.userData.email;
        //   var sRef = this.selectedLesson.ref
        //     .collection("students-" + this.selectedSemester)
        //     .doc(String(student.PERSON_ID));
        //   batch.set(sRef, tmp, { merge: true });
        // }
        // batch.update(this.selectedLesson.ref, {
        //   numberSelectedStudents: this.selectedAddStudents.length,
        // });
        // batch.commit();
      }

      this.selectedAddStudents = [];
      this.addStudentToLessonDialog = false;
    },
  },
  _saveRemoveSelectedStudents() {
    this.selectedRemoveStudents = [];
    this.removeStudentToLessonDialog = false;
  },
};
</script>

 
<style scoped>
.v-btn {
  text-transform: none;
}
/* Fix the first column (№) */
.fixed-table >>> th:first-child,
.fixed-table >>> td:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 3;
  border-right: 1px solid #ddd;
}

/* Fix the second column (Шалгалтын төрөл) */
.fixed-table >>> th:nth-child(2),
.fixed-table >>> td:nth-child(2) {
  position: sticky;
  left: 50px; /* Adjust width based on column size */
  background: white;
  z-index: 3;
  border-right: 1px solid #ddd;
}
</style>

<!-- todo

1. exams unshix
2. suragch nemeh collection oloh
3. hadgalah, ilgeeh
4. status unshih -->