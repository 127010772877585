<template>
  <v-app>
    <v-container class="py-10" fluid>

      <v-card class="mt-6">
        <div class="card-header-padding">
          <v-row justify="space-between">
            <v-col cols="6">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">Шалгалт</h5>
            </v-col>
          </v-row>
        </div>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="red"
        ></v-progress-linear>
        <v-row class="mx-4" justify="end">
          <v-col cols="9"
            ><p class="text-typo"></p>

            <v-alert
              color="#d81b60"
              class="pt-4"
              text
              prominent
              icon="mdi-information-slab-circle"
            >
              <p class="mb-0">
                1. Туxайн шалгалт дээр нэмж, xасаx үйлдлээр шалгалт өгөx сурагчийг бүрдүүлнэ.
              </p>
              <p class="mb-0">2. ҮНЭЛГЭЭ+ товчийг ашиглаж дүн оруулна.</p>
            </v-alert>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-select
              clearable
              v-if="allClassGroups"
              return-object
              v-model="selectedClassGroup"
              :items="allClassGroups"
              item-text="name"
              item-value="id"
              label="Бүлэг сонгоx"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col>
                    <span class="text-typo"
                      >{{ item.index }}.
                      <span class="blue--text">{{ item.name }}</span></span
                    >
                  </v-col>

                  <v-col class="text-end">
                    <small style="color: #bbb"
                      >{{ item.ACADEMIC_LEVEL }} -р анги</small
                    >
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <template v-if="selectedClassGroup">
          <v-card-text v-if="selectedClassGroup && renderComponent">
            <StateExamWidget
              :selectedClassGroup="selectedClassGroup"
            ></StateExamWidget>
          </v-card-text>

          <v-progress-linear
            v-if="selectedClassGroup && renderComponent == false"
            indeterminate
            color="red"
          ></v-progress-linear>
        </template>
        <template v-else>
          <v-alert
            color="#F8BBD0"
            class="pt-4 mx-8"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <div style="color: #d81b60; font-weight: normal">
              Ангиа сонгож, туxайн ангийн ээлжит xичээлийг xараx боломжтой.
            </div>
          </v-alert>
        </template>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import StateExamWidget from "./StateExamWidget.vue";
export default {
  data() {
    return {
      selectedStep: 0,
      finalSteps: [
        { label: "Хянах самбар", description: "Overview of the dashboard" },
        { label: "Журнал нэтгэл", description: "Journal and records" },
        { label: "Хичээлийн ДҮН", description: "Grade results overview" },
        { label: "Судалгаахууны дүн", description: "Research scores" },
        { label: "Шалгалтын нэтгэл", description: "Exam reports" },
        { label: "Төгсөлтийн бүртгэл", description: "Graduation registration" },
        { label: "Баримт бичиг", description: "Document records" },
        {
          label: "Төгсөгчөөс элсэх бүртгэл",
          description: "Alumni registration",
        },
      ],

      renderComponent: true,
      selectedClassGroup: null,
      allClassGroups: null,
      departments: null,
      allWeeks: null,
      currentSelectedSemester: null,
      headerNames3: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          width: "1%",
          value: "index",
        },
        {
          text: "Xичээлийн нэр",
          value: "courseInfo.COURSE_NAME",
          sortable: true,
          width: "30%",
        },
        {
          text: "Төрөл",
          align: "start",
          value: "esisLessonType",
          width: "14%",
        },
        {
          text: "Бүлгүүд",
          align: "start",
          value: "classGroups",
          sortable: true,
          width: "25%",
        },
        {
          text: "Xувиарууд",
          align: "start",
          value: "calDatas",
          sortable: true,
          width: "30%",
        },
        // {
        //   text: "Xуваарь+",
        //   align: "start",
        //   value: "calplus",
        //   width: "5%",
        // },
        // {
        //   text: "Үйлдэл",
        //   align: "start",
        //   value: "actions",
        // },
      ],
      teachers: null,
      teacherLessons: null,
    };
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _findClassGroups() {
      this.loading = true;
      this.allClassGroups = [];

      var filters = [];
      if (
        this.userData.role == "schoolmanager" &&
        this.userData.managerScope &&
        this.userData.managerScope.length < 3
      ) {
        this.userData.managerScope.forEach((scope) => {
          console.log(scope);
          if (scope == 1) {
            filters.push(1, 5);
          }
          if (scope == 2) {
            filters.push(6, 9);
          }
          if (scope == 3) {
            filters.push(10, 12);
          }
        });
      }
      console.log(filters);

      var query = this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "desc");

      if (this.userData.managerScope && this.userData.managerScope.length < 3) {
        query = query
          .where("index", "<=", Math.max(...filters))
          .where("index", ">=", Math.min(...filters));
      }

      query.get().then((docs) => {
        this.departments = [];
        this.classGroups = [];
        var counter = 0;
        this.numberOfAllGroups = 0;
        docs.forEach((doc) => {
          this.countOfPrograms = 0;
          let dep = doc.data();
          dep.id = doc.id;
          dep.ref = doc.ref;
          dep.classGroups = null;
          this.loading = true;
          dep.ref
            .collection("programs")
            .where("deleted", "==", false)
            .orderBy("name", "asc")
            .get()
            .then((docs) => {
              dep.classGroups = [];
              dep.numberOfStudents = 0;
              docs.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                let prog = doc.data();
                prog.id = doc.id;
                prog.ref = doc.ref;
                // if (
                //   prog.numberOfStudents != undefined &&
                //   prog.numberOfStudents != null
                // )
                //   dep.numberOfStudents =
                //     dep.numberOfStudents + prog.numberOfStudents;
                // prog.index = counter++;
                // this.numberOfAllGroups++;
                // prog.loading = false;
                // console.log(prog);
                counter++;
                prog.index = counter;
                dep.classGroups.push(prog);
                if (!this.selectedClassGroup) this.selectedClassGroup = prog;
                this.allClassGroups.push(prog);
              });
              this.loading = false;
            });
          this.departments.push(dep);
        });
        this.loading = false;
      });
    },
    _getDay(dayIndex) {
      var dayName = null;
      switch (dayIndex) {
        case 1:
          dayName = "Да";
          break;
        case 2:
          dayName = "Мя";
          break;
        case 3:
          dayName = "Лх";
          break;
        case 4:
          dayName = "Пү";
          break;
        case 5:
          dayName = "Ба";
          break;
        case 6:
          dayName = "Бя";
          break;
        case 7:
          dayName = "Ня";
          break;
      }
      return dayName;
    },
    _getFormatedCalData(lesson) {
      var calDatas = [];
      if (lesson._calDatas && lesson._calDatas.length > 0) {
        for (const xx of lesson._calDatas) {
          var calData = xx.split("-");
          if (
            calData[0] &&
            calData[0] == String(this.currentSelectedSemester)
          ) {
            var calObject = {
              semester: Number(calData[0]),
              calendarVersion: Number(calData[1]),
              garag: Number(calData[2]),
              garagName: this._getDay(Number(calData[2])),
              calendarEelj: Number(calData[3]),
              time: Number(
                calData[4].startsWith("0") ? calData[4].slice(1) : calData[4]
              ),
            };
            calDatas.push(calObject);
          }
        }
        return calDatas;
      } else return null;
    },
  },
  components: {
    StateExamWidget,
  },
  watch: {
    selectedClassGroup(val) {
      if (val) {
        this.forceRerender();
      }
    },
  },
  created() {
    this.currentSelectedSemester = this.$store.state.schoolCurrentSemester;
    this._findClassGroups();
    this.loading = true;

    this.userData.school.ref
      .collection("teachers")
      .where("role", "==", "teacher")
      .where("deleted", "==", false)
      .orderBy("FIRST_NAME", "asc")
      .onSnapshot((docs) => {
        this.teachers = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          let teacher = doc.data();
          teacher.id = doc.id;
          teacher.ref = doc.ref;
          if (!teacher.moved) {
            counter++;
            teacher.index = counter;
            this.teachers.push(teacher);
          }
        });
        this.loading = false;
      });
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          counter++;
          ll.index = counter;
          ll.uniqueId = ll.id + counter;
          ll.name3 = counter + ". " + ll.name2;
          list.push(ll);
        }
      }
      return list;
    },
    allLessonTime() {
      if (this.teacherLessons) {
        var summe = 0;
        for (const ll of this.teacherLessons) {
          if (
            ll._calDatas &&
            ll._calDatas.filter((xx) => xx[0] == this.currentSelectedSemester)
          ) {
            summe = summe + ll._calDatas.length;
          }
        }
      }
      return summe;
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title2] {
  position: relative;
}
</style>
 